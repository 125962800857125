import React, { Component } from "react";
import Recaptcha from "react-google-recaptcha";
import Formol, { Field } from "formol/lib/formol";
import { TransitionGroup, CSSTransition } from "react-transition-group";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const ThankYouOverlay = ({ formSuccess, className, formresponseheader, formresponsetext }) => {
  if (formSuccess) {
    return (
      <div className={`thank-you-overlay ${className || ""}`}>
        <h2>{formresponseheader}</h2>
        <p>{formresponsetext}</p>
      </div>
    );
  } else {
    return null;
  }
};

export default class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      "g-recaptcha-response": "",
      formSuccess: false,
      formSubmitted: false,
    };
  }
  /* Here’s the juicy bit for posting the form submission */

  handleSubmit = (submission) => {
    const { formSubmitted, formSuccess } = this.state;
    if (formSubmitted || formSuccess) return;
    console.log(submission);
    this.setState({ formSubmitted: true });
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        subject: "Contact from INT Contact Form",
        "form-name": "contact",
        ...submission,
        ...this.state,
      }),
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          this.setState({ formSuccess: true });
        }
      })
      .catch((error) => alert(error));
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleRecaptcha = (value) => {
    this.setState({ "g-recaptcha-response": value });
  };

  recaptchaRef = React.createRef();

  render() {
    const { formresponseheader, formresponsetext } = this.props;
    const { formSubmitted, formSuccess } = this.state;

    return (
      <div className={`contact-form-container ${formSubmitted ? "submitted" : ""}`} id="content">
        <div className="contact-form">
          <TransitionGroup component={null}>
            <CSSTransition classNames="thank-you-animation" timeout={{ enter: 500, exit: 500 }} key={formSuccess}>
              <ThankYouOverlay
                formSuccess={formSuccess}
                formresponseheader={formresponseheader}
                formresponsetext={formresponsetext}
              />
            </CSSTransition>
          </TransitionGroup>

          <Formol
            item={{ name: "", email: "", message: "" }}
            name="contact"
            method="post"
            action="/contact/"
            data-netlify="true"
            data-netlify-recaptcha="true"
            onSubmit={this.handleSubmit}
            submitText="Send Message"
            classes={{
              submit: "btn",
            }}
          >
            <input type="hidden" name="form-name" value="contact" />
            <Field name="name" required>
              Name
            </Field>
            <Field name="email" type="email" required>
              Email
            </Field>
            <Field name="message" type="area" required>
              Message
            </Field>
            <div className="recaptcha-container">
              <Recaptcha
                className="recaptcha"
                ref="recaptcha"
                sitekey="6LcGgJwUAAAAALnuIlp5u0fWqS_FB6dUJ6cDxiCb"
                onChange={this.handleRecaptcha}
              />
              <div data-netlify-recaptcha="true" />
            </div>
          </Formol>
        </div>
      </div>
    );
  }
}
