import React from "react";

class ScrollButton extends React.Component {
  componentDidMount() {
    import("moveto").then((moveto) => {
      this.MoveTo = moveto.default;
    });
  }
  scrollTo = () => {
    const { target = "#content" } = this.props;
    const moveTo = new this.MoveTo();

    const targetElement = document.querySelector(target);

    moveTo.move(targetElement);
  };

  render() {
    return (
      <button onClick={this.scrollTo} className="scroll-container" aria-label="Scroll Down">
        <span className="span" />
      </button>
    );
  }
}

export default function Header({ banner, children, title, subtitle, noScroll }) {
  return (
    <div className="page-header" style={{ backgroundImage: `url(${banner.childImageSharp.fluid.src})` }}>
      <div className="typewriter">
        <h1 id="title">{title}</h1>
      </div>
      <div className="subtitle-container">
        <h3 id="subtitle" dangerouslySetInnerHTML={{ __html: subtitle.replace(/(?:\r\n|\r|\n)/g, "<br>") }} />
      </div>
      {!noScroll ? <ScrollButton /> : null}
      {children}
      <div className="transition-gradient" />
    </div>
  );
}
