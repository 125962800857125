import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";

import Header from "../components/Header";
import ContactForm from "../components/ContactForm";

export default function ContactPage({ data }) {
  const { markdownRemark } = data;
  const { frontmatter } = markdownRemark;
  return (
    <main>
      <SEO
        title={`${frontmatter.seotitle ? frontmatter.seotitle : frontmatter.title} | Inland Northwest Technologists`}
        keywords={[`Inland Northwest Technologists`, `application`, `react`]}
        description={frontmatter.seodescription}
      />
      <Header {...frontmatter} />
      <ContactForm
        formresponseheader={frontmatter.formresponseheader}
        formresponsetext={frontmatter.formresponsetext}
      />
    </main>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        subtitle
        banner {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        formresponseheader
        formresponsetext
      }
    }
  }
`;
